import { message, Upload } from "antd";
import { Map } from "immutable";
var CryptoJS = require("crypto-js");

export const state = [
  {
    id: "1",
    name: "Johor",
  },
  {
    id: "2",
    name: "Kedah",
  },
  {
    id: "3",
    name: "Kelantan",
  },
  {
    id: "4",
    name: "Melaka",
  },
  {
    id: "5",
    name: "Negeri Sembilan",
  },
  {
    id: "6",
    name: "Pahang",
  },
  {
    id: "7",
    name: "Perak",
  },
  {
    id: "8",
    name: "Pulau Pinang",
  },
  {
    id: "9",
    name: "Sarawak",
  },
  {
    id: "10",
    name: "Selangor",
  },
  {
    id: "11",
    name: "Terengganu",
  },
  {
    id: "12",
    name: "Kuala Lumpur",
  },
  {
    id: "13",
    name: "Labuan",
  },
  {
    id: "14",
    name: "Sabah",
  },
  {
    id: "14",
    name: "Putrajaya",
  },
];

export function clearToken() {
  localStorage.removeItem("id_token");
}

export function getAccessToken() {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken;
}

export function getLoginData() {
  const decrypt = aesDecrypt(localStorage.getItem("loginData"));
  const profileData = JSON.parse(decrypt);
  return profileData;
}

export function aesEncrypt(text) {
  var encrypted = CryptoJS.AES.encrypt(text, process.env.REACT_APP_AUTH_KEY);

  return encrypted.toString();
}

export function aesDecrypt(text) {
  var decrypted = CryptoJS.AES.decrypt(text, process.env.REACT_APP_AUTH_KEY);

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function beforeUpload(file) {
  const isLt4M = file.size / 1024 / 1024 < 4;
  if (!isLt4M) {
    message.error("File size must be smaller than 4 MB");
  }
  return file.size / 1024 / 1024 < 4 ? true : Upload.LIST_IGNORE;
}

export function customRequest({ file, onSuccess }) {
  setTimeout(async () => {
    onSuccess("ok");
  }, 0);
}

export function getToken() {
  try {
    const idToken = localStorage.getItem("id_token");
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function arrayEqual(array1, array2) {
  return array1.sort().toString() === array2.sort().toString();
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}
