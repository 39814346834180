import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Row } from "antd";
import appActions from "@iso/redux/app/actions";
import TopbarWrapper from "./Topbar.styles";
import { MenuItems } from "./MenuItems";

const { Header } = Layout;
const { toggleCollapsed } = appActions;

export default function Topbar() {
  const [selectedItem, setSelectedItem] = React.useState("");
  const customizedTheme = useSelector(
    (state) => state.ThemeSwitcher.topbarTheme
  );
  const dispatch = useDispatch();
  const handleToggle = React.useCallback(
    () => dispatch(toggleCollapsed()),
    [dispatch]
  );
  const [handleClicked, setHandleClicked] = useState(false);

  const styling = {
    background: customizedTheme.backgroundColor,
    position: "fixed",
    width: "100%",
    height: 70,
    backgroundColor: '#404040',
  };
  return (
    <TopbarWrapper>
      <Header style={styling} className={"isomorphicTopbar collapsed"}>
        {/* <div className="isoLeft">
       
        </div> */}
        <nav className="NavbarItems">
          <h1 className="navbar-logo">
            <a src="./"><img
              src={"http://myb2b.my/images/myb2b_logo.png"}
              alt="Logo"
              width="80%"
            />
            </a>
            <i className="fab fa-react"></i>
          </h1>
          <div
            className="menu-icon"
            onClick={() => {
              setHandleClicked(!handleClicked);
            }}
          >
            <i className={handleClicked ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <ul>
            <ul className={handleClicked ? "nav-menu active" : "nav-menu"}>
              {MenuItems.map((item, index) => {
                return (
                  <li>
                    <a className={item.cName} href={item.url}>
                      {item.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </ul>
        </nav>

        {/* <ul className="isoRight">
          <li className="isoSearch">
            <TopbarSearch />
          </li>

          <li
            onClick={() => setSelectedItem('notification')}
            className={selectedItem ? 'isoNotify active' : 'isoNotify'}
          >
            <TopbarNotification />
          </li>

          <li onClick={() => setSelectedItem('message')} className="isoMsg">
            <TopbarMessage />
          </li>
          <li onClick={() => setSelectedItem('addToCart')} className="isoCart">
            <TopbarAddtoCart />
          </li>

          <li onClick={() => setSelectedItem('user')} className="isoUser">
            <TopbarUser />
          </li>
        </ul> */}
      </Header>
    </TopbarWrapper>
  );
}
