export const MenuItems = [
  {
    title: "Home",
    url: "/",
    check: true,
    cName: "nav-links",
  },
  {
    title: "Search Your Business",
    url: "/searchYourBusiness",
    cName: "nav-links",
  },
  {
    title: "Login",
    url: "/signin",
    check: true,
    cName: "nav-links",
  },
  {
    title: "Registration",
    url: "/signup",
    check: true,
    cName: "nav-links-mobile",
  },
];
