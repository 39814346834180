import React, { lazy, Suspense } from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import useWindowSize from "@iso/lib/hooks/useWindowSize";
import { useSelector, useDispatch } from "react-redux";
import appActions from "@iso/redux/app/actions";
import { Layout, Form, Input, Row, Col, Button } from "antd";
import ErrorBoundary from "./ErrorBoundary";
import { PUBLIC_ROUTE } from "./route.constants";
import Loader from "@iso/components/utility/loader";
import Topbar from "./containers/Topbar/OutsideTopbar";
import PageFooter from "./newComponent/Footer/Footer";

const { Content, Footer } = Layout;
const { toggleAll } = appActions;
const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));

const styles = {
  layout: { flexDirection: "row", overflowX: "hidden" },
  content: {
    padding: "70px 0 0",
    flexShrink: "0",
    background: "#f1f3f6",
    position: "relative",
  },
  footer: {
    background: "#ffffff",
    textAlign: "center",
    borderTop: "1px solid #ededed",
  },
};

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import("@iso/containers/Home/Home")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import("@iso/containers/Pages/404/404")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import("@iso/containers/Pages/500/500")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import("@iso/containers/Pages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import("@iso/containers/Pages/SignUp/SignUp")),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import("@iso/containers/Pages/ForgotPassword/ForgotPassword")
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import("@iso/containers/Pages/ResetPassword/ResetPassword")
    ),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    component: lazy(() =>
      import("@iso/containers/Authentication/Auth0/Auth0Callback")
    ),
  },
  {
    path: "/searchYourBusiness/:seo_name?",
    component: lazy(() =>
      import("@iso/containers/SearchYourBusiness/SearchYourBusiness")
    ),
    exact: true,
  },
  {
    path: "/businessDetail/:seo_name",
    component: lazy(() =>
      import("@iso/containers/SearchYourBusiness/BusinessDetail")
    ),
  },
];
function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  const dispatch = useDispatch();
  const appHeight = useSelector((state) => state.App.height);
  const { width, height } = useWindowSize();

  React.useEffect(() => {
    dispatch(toggleAll(width, height));
  }, [width, height, dispatch]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <Layout style={{ height: height }}>
                  <Topbar />
                  <Layout style={styles.layout}>
                    <Layout
                      className="isoContentMainLayout"
                      style={{
                        height: appHeight,
                      }}
                    >
                      <Content
                        className="isomorphicContent"
                        style={styles.content}
                      >
                        <div>
                          <route.component />
                        </div>
                      </Content>
                      <PageFooter />
                    </Layout>
                  </Layout>
                </Layout>
              </Route>
            ))}
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
