import React, { useEffect, useState } from "react";
import { Layout, Col, Row, Form, Input, Button, message } from "antd";
import ApiCall from "../../library/helpers/ApiCall";
const { Content, Footer } = Layout;
const { TextArea } = Input;

const PageFooter = () => {
  const [form] = Form.useForm();
  const [footerList, setFooterList] = useState([]);

  useEffect(() => {
    ApiCall.get("/footer/links/all").then((response) => {
      setFooterList(response.data);
    });
  }, []);

  const submitForm = (e) => {
    var formData = new FormData();
    formData.append("name", e.name);
    formData.append("phone", e.phone);
    formData.append("email", e.email);
    formData.append("message", e.message);
    ApiCall.post("/contact", formData).then((response) => {
      if (response.data == "success") {
        message.success("Succesfully sended contact form!");
        form.resetFields();
      } else message.error("Fail to send contact form");
    });
  };

  return (
    <Footer style={{ background: "#404040", color: "grey" }}>
      <div style={{ padding: "40px 40px" }}>
        <Row gutter={[16, 16]}>
          <Col lg={10} md={10} sm={10} xs={24}>
            <img
              src={"https://myb2b.my/images/myb2b_logo.png"}
              alt="Logo"
              width="20%"
            />
          </Col>
          <Col lg={7} md={7} sm={7} xs={24}>
            <Row
              style={{
                display: "block",
                fontSize: "1.3em",
                paddingBottom: "10px",
              }}
            >
              <span>LATEST POSTS</span>
            </Row>
            {footerList.map((item, index) => {
              return (
                <Row style={{ width: "75%", fontSize: "0.95em" }} key={index}>
                  <a itemprop="url" target="_blank">
                    {item.link_name}
                  </a>
                </Row>
              );
            })}
          </Col>
          <Col lg={7} md={7} sm={7} xs={24}>
            <Row
              style={{
                display: "block",
                fontSize: "1.3em",
                paddingBottom: "10px",
              }}
            >
              <span>Contact Us</span>
            </Row>
            <Row style={{ width: "100%", fontSize: "0.95em" }}>
              <Form form={form} onFinish={submitForm}>
                <Form.Item name="name" rules={[{ required: true }]}>
                  <Input placeholder="Your Name" />
                </Form.Item>
                <Form.Item name="phone" rules={[{ required: true }]}>
                  <Input placeholder="Your Phone" />
                </Form.Item>
                <Form.Item name="email" rules={[{ required: true }]}>
                  <Input placeholder="Your Email" />
                </Form.Item>
                <Form.Item name="message" rules={[{ required: true }]}>
                  <TextArea placeholder="Your Message" />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit">Submit</Button>
                </Form.Item>
              </Form>
            </Row>
          </Col>
        </Row>
      </div>
    </Footer>
  );
};

export default PageFooter;
