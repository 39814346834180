import axios from "axios";
import { getAccessToken } from "./utility";

const accessToken = getAccessToken();

axios.interceptors.request.use(
  function (config) {
    config.baseURL = process.env.REACT_APP_B2B_KEY;
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    };
    // read this: https://github.com/axios/axios/issues/2024
    
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
